.innerHtmlDiv{

}
.innerHtmlDiv img{
    width: 100%;
}
i.fa-facebook-f {
    background: #3b5998
}
i.fa-twitter {
    background: #008fc6;
}
i.fa-instagram {
    background: #990dda;
}
i.fa-pinterest {
    background: #cb2027;
}
i.fa-weixin {
    background: #2BA245;
}
i.fab  {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    text-align: center;
    padding: 12px;
    color: #fff;
    font-size: 1.6em;
    box-sizing: border-box;
}